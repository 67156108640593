import Button from '../components/Button'
import Heading from '../components/Heading'

export default function Page404() {
  return (
    <div>
      <Heading align="center">Erreur 404: Page non trouvée</Heading>

      <Button href="/" text="Recharger la page" />
    </div>
  )
}
